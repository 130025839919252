import {
  alteryx_header_icon,
  alteryx_header_logo,
} from "../assets/images/index";
import { AlteryxLogo } from "../assets/images/svg";
const alteryxConfig = {
  name: "alteryx",
  apiUrl: "https://woolly-lab-api.stage.eugeniuses.com/api/",
  image: alteryx_header_logo,
  modelId: 5,
  title: "Alteryx - Customize Your Wallet",
  description: "alteryx description",
  shopifyStoreName: "shopify.staging.woollymade.com",
  shopifyAccessToken: "64b5d18563a2c60356a7c4e1c5686498",
  shopifyProductHalfWallet: "",
  shopifyProductKeychain: "",
  shopifyProductFullWallet: "",
  shopifyProductStickOnWallet: "",
  shopifyProductHalfWalletAlteryx: 8302104740067,
  headerContent: `HANDCRAFTED BY WOOLLY MADE<Image className='img cursor-pointer' draggable='false'  src='${alteryx_header_icon}'/>`,
  headerColor: "alteryx-header-color",
  btnClasses: "alteryx-btn",
  mainStepLogo: <AlteryxLogo />,
  footerClasses: "alteryx-footer-icon",
  dotClasses: "alteryx-dot",
  doneBtnClass: "btn-outline-alteryx",
  userName: "Alteryx",
  password: "7bJK0#P1RDsa",
  LeaveLink: "https://woollymade.com",
  sendgridApiKey: "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",
  apiKey: "AIzaSyA74fFrPQCiiVAQYjMxBYo3ynR941AZve0",
  sheetId: "1pS7PKI1wUsJZ9mYMPjal5YbZvztJBPZtPVeIyWM-R5U",
};

export default alteryxConfig;
